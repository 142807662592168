@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  background-color: #000000;
  scroll-behavior: smooth;
  overflow-y: scroll; /* Allow vertical scrolling */
}

/* Main container with snap-scroll */
main {
  scroll-snap-type: y mandatory; /* Snap scrolling in vertical direction */
  overflow-y: scroll; /* Allow vertical scrolling */
}

/* Snap section alignment */
section {
  scroll-snap-align: start; /* Align sections to the top when snapping */
}

/* Hero section background */
.hero {
  background-image: url("../public/hero.png");
  background-size: cover;
  background-position: center;
}

.services {
  background-image: url("../public/services.png");
  background-size: cover;
  background-position: center;
}

.styled_text {
  color: white;
  text-shadow: 4px 4px 0 gray, /* Right and Down border */ -4px 4px 0 gray,
    /* Left and Down border */ 4px -4px 0 gray,
    /* Right and Up border */ -4px -4px 0 gray; /* Left and Up border */
}

/* Hide scrollbar for WebKit browsers */
::-webkit-scrollbar {
  display: none;
}

/* From Uiverse.io by mobinkakei */
.loader {
  position: relative;
  width: 108px;
  display: flex;
  justify-content: space-between;
}
.loader::after,
.loader::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #fff;
  background-image: radial-gradient(circle 14px, #0d161b 100%, transparent 0);
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: eyeMove 10s infinite, blink 10s infinite;
}
@keyframes eyeMove {
  0%,
  10% {
    background-position: 0px 0px;
  }
  13%,
  40% {
    background-position: -15px 0px;
  }
  43%,
  70% {
    background-position: 15px 0px;
  }
  73%,
  90% {
    background-position: 0px 15px;
  }
  93%,
  100% {
    background-position: 0px 0px;
  }
}
@keyframes blink {
  0%,
  10%,
  12%,
  20%,
  22%,
  40%,
  42%,
  60%,
  62%,
  70%,
  72%,
  90%,
  92%,
  98%,
  100% {
    height: 48px;
  }
  11%,
  21%,
  41%,
  61%,
  71%,
  91%,
  99% {
    height: 18px;
  }
}

/* From Uiverse.io by StealthWorm */
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  overflow: hidden;
  height: 3rem;
  background-size: 300% 300%;
  cursor: pointer;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-family: "Avalors Personal Use";
  font-size: 12px;
  letter-spacing: 5px;
  color: #ffffff;
  text-shadow: 0 0 4px white;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.btn:hover {
  transform: scale(1.1);
}

.btn:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn:active .circle {
  background: #fe53bb;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* From Uiverse.io by vinodjangid07 */
.input-wrapper {
  width: fit-content;
  height: 45px;
  border-radius: 20px;
  padding: 5px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  background-color: #292524;
}

.icon {
  width: 30px;
  fill: rgb(255, 255, 255);
  margin-left: 8px;
  transition: all 0.3s;
}
.input {
  max-width: 170px;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 15px;
  background-color: #292524;
  color: white;
  font-size: 1em;
}
.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #292524 inset;
  -webkit-text-fill-color: #ffffff;
}
.Subscribe-btn {
  height: 100%;
  width: 95px;
  border: none;
  border-radius: 15px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  background-color: #ffffff;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
}
.arrow {
  /* display: none; */
  position: absolute;
  margin-right: 150px;
  transition: all 0.3s;
}
.input-wrapper:active .icon {
  transform: scale(1.3);
}
.Subscribe-btn:hover {
  color: white;
}
.Subscribe-btn:hover .arrow {
  margin-right: 0;
  animation: jello-vertical 0.9s both;
  transform-origin: right;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.Subscribe-btn:active {
  transform: scale(0.9);
}
